import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  DynamicImage,
  LoadingPage,
  PageContainer,
  Seo,
  Text,
} from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { mobile, useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import LoadingBar from './components/LoadingBar';
import Checklist from './components/Checklist';
import { normalizeStates } from 'utils/localization';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0 1rem;
  max-width: 26.25rem;
  width: 100%;
  margin: 0 auto;
`;

const ImageContainer = styled.div`
  padding-top: 3rem;
  max-width: 32.625rem;
  @media ${mobile} {
    padding-top: 1.5rem;
    max-width: 21.438rem;
  }
`;
const Image = styled(DynamicImage)`
  width: 100%;
`;

const Title = styled(Text).attrs({
  color: 'green110',
})`
  @media ${mobile} {
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const Analyzing = styled(Text)`
  margin: 1.5rem 0;
  transition: opacity ease-in-out 2s;
  opacity: 1;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
  color: #2a4b42;
  width: 100%;
`;

const LoadingBarStyled = styled(LoadingBar)`
  margin-bottom: 1.5rem;
`;

const StyledPageContainer = styled(PageContainer)`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fef3f3;
`;

const Calculating: FC = () => {
  const [progress, setProgress] = useState<number>(0);

  const data = useQuizData('calculating');
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user
  );
  const { goToResults, goToEmail } = useRouter();

  const { isMobile } = useQuery();

  usePageView({
    client_code: code,
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || ''
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || ''
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    email: user?.email,
  });

  const handleNavigationOnCounterEnd = () => {
    if (variant === 'funnel-email' || variant === 'email-first') {
      return goToEmail();
    }
    goToResults();
  };

  if (!data) {
    return null;
  }
  const {
    title,
    progressTitle,
    imageUrl,
    list,
    modalQuestion,
    modalAnswer1,
    modalAnswer2,
    showModal,
  } = data;

  return (
    <>
      <Seo />
      <StyledPageContainer>
        <Header color="#FEF3F3" />
        <ContentContainer>
          {imageUrl ? (
            <ImageContainer>
              <Image src={imageUrl} alt="molecule" />
            </ImageContainer>
          ) : null}
          {title ? <Title>{title}</Title> : null}
          <Analyzing>{progressTitle}</Analyzing>
          <LoadingBarStyled progress={progress} />
          <Checklist
            list={list}
            onEnd={handleNavigationOnCounterEnd}
            onProgress={progress => setProgress(progress)}
            modalQuestion={modalQuestion}
            modalAnswer1={modalAnswer1}
            modalAnswer2={modalAnswer2}
            showModal={showModal}
          />
        </ContentContainer>
      </StyledPageContainer>
    </>
  );
};

export default Calculating;
